import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import Gallery from 'react-grid-gallery';
import Helmet from 'react-helmet';

import Footer from '../../components/footer';
import Layout from '../../components/layout';
import NavSection from '../../components/nav-section';

const Caption = ({ caption, link, title }) => (
  <div className="caption-container">
    <h1>{title}</h1>
    <p>{caption}</p>
    <div className="caption-action">
      <Link to={link}>LEARN MORE</Link>
      <Link to="/#inquiry">INQUIRE</Link>
      <Link to="/tours">VIRTUAL TOUR</Link>
    </div>
  </div>
);

export class GalleryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IMAGES: get(this, 'props.data.allContentfulGallery.edges'),
      FILTERED_IMAGES: get(this, 'props.data.allContentfulGallery.edges'),
      all: 'active',
      rooms: '',
      exterior: '',
      weddings: '',
      events: '',
      shoots: '',
    };
  }

  componentDidMount = () => {
    const { IMAGES } = this.state;
    const newImages = [];
    IMAGES.map(img => {
      const tags = img.node.tags.concat(['all']);
      const imageSet = {
        src: img.node.image.file.url,
        thumbnail: img.node.image.file.url,
        caption: (
          <Caption title={img.node.title} caption={img.node.caption.caption} link={img.node.link} />
        ),
        tags: tags,
      };
      newImages.push(imageSet);
      return newImages;
    });
    this.setState({ IMAGES: newImages, FILTERED_IMAGES: newImages });

    // check hash
    if (typeof document !== 'undefined') {
      const interval = setInterval(() => {
        if (document.readyState === 'complete') {
          const hash = window.location.hash.replace('#','');
          if(hash) this.filterHandler(hash);
          clearInterval(interval);
        }
      }, 100);
    }
  };

  filterHandler = (target) => {
    this.setState({
      all: '',
      rooms: '',
      exterior: '',
      weddings: '',
      events: '',
      shoots: '',
    });
    const { IMAGES } = this.state;
    let FILTERED_IMAGES = IMAGES.filter(img => {
      return img.tags.some(obj => obj === target);
    });
    this.setState({ [target]: 'active', FILTERED_IMAGES });
  };

  render() {
    const {
      FILTERED_IMAGES,
      all,
      rooms,
      exterior,
      weddings,
      events,
      shoots,
    } = this.state;
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');

    const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Gallery" />
        <div className="container">
          <div className="gallery-header">
            <h1>Gallery</h1>
          </div>
        </div>

        <section id="link-tabs" className="pt-4 pb-4 gallery-link-tabs">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav justify-content-center gallery-filters">
                  <li className="font-gtaM">
                    <input
                      id="all"
                      className={`center-btns nav-link quick-link ${all}`}
                      onClick={(e) => this.filterHandler(e.target.id)}
                      value="01 / ALL"
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      id="rooms"
                      className={`center-btns nav-link quick-link ${rooms}`}
                      onClick={(e) => this.filterHandler(e.target.id)}
                      value="02 / ROOMS"
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      id="exterior"
                      className={`center-btns nav-link quick-link ${exterior}`}
                      onClick={(e) => this.filterHandler(e.target.id)}
                      value="03 / EXTERIOR"
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      id="weddings"
                      className={`center-btns nav-link quick-link ${weddings}`}
                      onClick={(e) => this.filterHandler(e.target.id)}
                      value="04 / WEDDINGS"
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      id="events"
                      className={`center-btns nav-link quick-link ${events}`}
                      onClick={(e) => this.filterHandler(e.target.id)}
                      value="05 / EVENTS"
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      id="shoots"
                      className={`center-btns nav-link quick-link ${shoots}`}
                      onClick={(e) => this.filterHandler(e.target.id)}
                      value="06 / SHOOTS"
                      type="button"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="container pt-5 pb-5 clearfix mb-5">
          <Gallery
            className="gal-mobile"
            images={FILTERED_IMAGES}
            enableImageSelection={false}
            backdropClosesModal={true}
            rowHeight="auto"
          />
        </div>

        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default GalleryPage;

export const pageQuery = graphql`
  query GalleryPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulGallery(sort: { fields: createdAt }) {
      edges {
        node {
          caption {
            caption
          }
          image {
            file {
              url
            }
          }
          tags
          title
          link
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
